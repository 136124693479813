import { ListItemIcon, styled } from '@mui/material';

export const SignUpTextContainerStyled = styled('div')(({ theme }) => ({
    paddingTop: '12px',
    paddingRight: '40px',
    [theme.breakpoints.down('md')]: {
        padding: '10px',
    },
    maxWidth: '800px',
}));

export const SignUpFormContainerStyled = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    h2: {
        fontFamily: 'Right Grotesk',
    },
    'span, p': {
        fontFamily: 'Object Sans',
    },
}));

export const CustomListItemIcon = styled(ListItemIcon)`
    min-width: auto;
    padding-right: 15px;
`;

// we need the below fix as at low width resolutions, the auth0 form would expand on all visible hight
export const SignUpFormStyled = styled('div')`
    .auth0-lock-cred-pane-internal-wrapper {
        height: auto !important;
    }
`;
