import React, { useEffect } from 'react';
import Auth0Lock from 'auth0-lock';
import ConstructionIcon from '@mui/icons-material/Construction';
import DescriptionIcon from '@mui/icons-material/Description';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import HandshakeIcon from '@mui/icons-material/Handshake';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

import { STAY_IN_TOUCH_ID, stayInTouchRequiredFix } from './utils/stayInTouchRequiredFix';
import {
    SignUpFormContainerStyled,
    SignUpTextContainerStyled,
    SignUpFormStyled,
    CustomListItemIcon,
} from './SignupForm.styles';

const LOCK_CONTAINER_ID = 'signup-container';

export type SignupFormProps = {
    auth0Domain: string;
    auth0ClientId: string;
    auth0AllowedConnections?: string[];
    redirectUrl: string;
};

export const SignupForm = ({ auth0Domain, auth0ClientId, auth0AllowedConnections, redirectUrl }: SignupFormProps) => {
    useEffect(() => {
        const lock = new Auth0Lock(auth0ClientId, auth0Domain, {
            container: LOCK_CONTAINER_ID,
            allowedConnections: auth0AllowedConnections,
            allowLogin: false,
            allowSignUp: true,
            initialScreen: 'signUp',
            mustAcceptTerms: true,
            signUpFieldsStrictValidation: true,
            avatar: null,
            theme: {
                primaryColor: '#005ad7',
                logo: `${process.env.PUBLIC_URL}/truvity.svg`,
            },
            languageDictionary: {
                signUpTerms:
                    'By signing up, you agree to our <a href="https://truvity.com/terms" _target="blank">terms & conditions</a> and <a href="https://truvity.com/privacy" _target="blank">privacy policy</a>.',
            },
            auth: {
                redirect: true,
                redirectUrl,
            },
            additionalSignUpFields: [
                {
                    type: 'checkbox',
                    name: STAY_IN_TOUCH_ID,
                    prefill: 'false',
                    placeholder: 'Stay in touch with Truvity',
                    validator: (value) => ({
                        valid: value === 'true',
                        hint: 'Mandatory',
                    }),
                },
            ],
        });

        lock.on('signup submit', stayInTouchRequiredFix);
        lock.show();

        return () => lock.hide();
    }, [auth0AllowedConnections, auth0ClientId, auth0Domain, redirectUrl]);

    return (
        <SignUpFormContainerStyled>
            <SignUpFormStyled id={LOCK_CONTAINER_ID} />
            <SignUpTextContainerStyled>
                <h2>Start building today</h2>

                <span>
                    Get free early-access to the Truvity platform and play with the future of identity. Make instant
                    trust a reality for your usecase and make document exchange simple.
                </span>
                <Typography mb={3} mt={3}>
                    <strong>Get access to:</strong>
                </Typography>
                <Grid container spacing={2}>
                    <ListItem>
                        <CustomListItemIcon>
                            <ConstructionIcon />
                        </CustomListItemIcon>
                        <ListItemText
                            primary="The building blocks to implement a triangle of trust, and get access to the self-sovereign
                            identity technology stack"
                        />
                    </ListItem>
                    <ListItem>
                        <CustomListItemIcon>
                            <DescriptionIcon />
                        </CustomListItemIcon>
                        <ListItemText
                            primary="Start using the benefits of verifiable credentials, built-in verification mechanics and data
                            collection/resharing protocols"
                        />
                    </ListItem>
                    <ListItem>
                        <CustomListItemIcon>
                            <AirplanemodeActiveIcon />
                        </CustomListItemIcon>
                        <ListItemText
                            primary="Access our 'Travel application: Digital boarding pass and COVID test credentials' as a reference point, guiding you through the key
                            concepts"
                        />
                    </ListItem>
                    <ListItem>
                        <CustomListItemIcon>
                            <HandshakeIcon />
                        </CustomListItemIcon>
                        <ListItemText
                            primary="Build with our extensive developer documentation and get direct support with your questions
                            from us and the community"
                        />
                    </ListItem>
                </Grid>
            </SignUpTextContainerStyled>
        </SignUpFormContainerStyled>
    );
};
