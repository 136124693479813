import React, { useEffect } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { SignupForm } from '../modules/SignupForm';
import { auth0ClientId, helmValues } from '../../utils/deploy-params';

export const HomePage = () => {
    const sendDataToGTM = useGTMDispatch();

    useEffect(() => {
        sendDataToGTM({ event: 'previousUserUrl', value: document.referrer });
    }, [sendDataToGTM]);

    return (
        <SignupForm
            auth0ClientId={auth0ClientId}
            auth0Domain={helmValues.auth0Domain}
            auth0AllowedConnections={helmValues.auth0AllowedConnections}
            redirectUrl={helmValues.auth0AuthRedirect}
        />
    );
};
