/**
 * This checkbox name must be synchronized with the consent check in the `check-consent` Auth0 action.
 * You can find the action in: `auth0/actions/pre-user-registration/check-consent/code.js`.
 */
export const STAY_IN_TOUCH_ID = 'stay_in_touch';
const STAY_IN_TOUCH_REQUIRED_ID = `${STAY_IN_TOUCH_ID}-required`;

let stayInTouchRequired: HTMLElement | null = null;

const clearWarning = (checkboxContainer: HTMLElement) => {
    checkboxContainer.style.border = 'none';

    stayInTouchRequired && stayInTouchRequired.remove();

    stayInTouchRequired = null;
};

export const stayInTouchRequiredFix = () => {
    const checkbox = document.querySelector<HTMLInputElement>(`[name='${STAY_IN_TOUCH_ID}']`);
    const checkboxContainer = checkbox && checkbox.closest<HTMLElement>('div');

    if (checkbox && checkboxContainer) {
        // if we have not yet shown the warning
        if (!checkbox.checked && stayInTouchRequired === null) {
            checkboxContainer.style.border = '1px solid red';
            checkboxContainer.style.borderRadius = '3px';

            checkboxContainer.insertAdjacentHTML(
                'afterend',
                `<div id='${STAY_IN_TOUCH_REQUIRED_ID}' class="auth0-lock-error-msg">Mandatory</div>`,
            );

            checkbox.onchange = () => {
                // if we have already shown the warning
                if (checkbox.checked && stayInTouchRequired !== null) {
                    clearWarning(checkboxContainer);
                }
            };

            stayInTouchRequired = document.querySelector(`#${STAY_IN_TOUCH_REQUIRED_ID}`);
        }
        // if we have already shown the warning
        else if (checkbox.checked && stayInTouchRequired !== null) {
            clearWarning(checkboxContainer);
        }
    }
};
